<template>
    <slot />
</template>

<script lang="ts" setup>
import type { InternalFormElementValue } from '@core-types/components/CoreUIForm'
import { type CoreUiFormRadioGroupProvide, SymbolCoreUiFormRadioGroup } from '@core/app/composables/components'

const inputValue = defineModel<string | number | null | undefined>()
const formInputValue = defineModel<InternalFormElementValue<boolean | number | string | null>>('form')

// TODO: turn into a normal string variable once useId() lands in Vue 3.5
const groupId = ref<string>('')

onMounted(() => {
    groupId.value = useId()
})

provide<CoreUiFormRadioGroupProvide>(SymbolCoreUiFormRadioGroup, {
    name: groupId,
    inputValue: inputValue,
    formInputValue: formInputValue,
    isRadioChecked: (value) => {
        if (inputValue.value) return value === inputValue.value
        return value === formInputValue.value?.__v
    },
})

</script>
